import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Layout from '../components/layout'
import BackgroundImage from 'gatsby-background-image'
import { gsap } from 'gsap'
import utils from "../components/utils";
import { renderRichText } from "gatsby-source-contentful/rich-text"
import ContactForm from "../components/contact-form";
import CircleLink from "../components/circle-link";
import SEO from "../components/seo";
import { options } from "../cms-helpers";

export default function PersonTemplate({ data }) {

  const person = data.contentfulPerson;

  useEffect(() => {
    utils.resetPage()
    gsap.to('body', {
      opacity: 1,
      duration: 0.5,
      ease: "none"
    })
    utils.fadeNav(false)
    gsap.to('#nav', {
      opacity: 1,
      duration: 0.7,
      ease: "power2.out"
    })
    gsap.to('.service-post-content', {
      opacity: 1,
      duration: 0.7,
      delay: 0.5,
      ease: "power2.out"
    })
  }, [])

  const content = (
    <div className={`relative`}>
      <div className="lg:min-h-screen flex flex-col px-4 lg:px-12 p-12 justify-end relative">
        <div className="lg:max-w-lg lg:absolute lg:top-1/2 lg:transform lg:-translate-y-1/2 pt-8 lg:pt-0">
          <div className="font-sc capitalize text-lg lg:text-xl mb-2 lg:mb-0">{person.name}</div>
        </div>
      </div>
      <section className={`service-post-content lg:transform lg:-translate-y-60`} style={{
        opacity: 0
      }}>
        <div className="px-4 lg:px-12 p-12 body-content">
          <div className="max-w-lg">
            <div className="mb-12">
              <p className="font-mabry tracking-wider uppercase text-sm">{person.position}</p>
            </div>
            {renderRichText(person.longDescription, options)}

            <div className="mt-8">
              <CircleLink title="Go Back" isLink={true} to="/about" onPress={() => {
                utils.fadeOutFromPurple()
              }} />
            </div>
          </div>
        </div>
      </section>
    </div>
  )

  return (
    <Layout>
      <SEO
        title={person.name}
        description={person.shortDescription.shortDescription}
        image={person.image.fluid.src}
      />
      <section className="person-post lg:grid lg:grid-cols-2 gap-0 bg-sand">
        <div data-scroll data-scroll-sticky data-scroll-target=".person-post" className={`h-75vh mobile-landscape-h-fix lg:min-h-screen`}>
          <BackgroundImage backgroundColor={utils.getSandHex()} fluid={person.image.fluid} className={`w-full h-75vh mobile-landscape-h-fix lg:min-h-screen blog-post__image `} />
        </div>
        {content}
      </section>
      <ContactForm />
    </Layout>
  )
}

export const query = graphql`
  query PersonBySlug($slug: String!) {
    contentfulPerson(slug: { eq: $slug }) {
      longDescription {
        raw
      }
      position
      slug
      name
      image {
        fluid(quality: 90, maxWidth: 1080) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      shortDescription {
        shortDescription
      }
    }
  }
`






































